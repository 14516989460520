import Image from 'next/image';

import { getImgData } from '@/utils/images';
import { Section, Title, Paragraph } from '@/components/core';
import { Container } from '@/components/core/flexbox';
import Intro from '../Intro';
import {
  Wrapper,
  Content,
  InfoContent,
  ImageContent,
  Footer,
  FooterTitle,
  WrapperInfo,
} from './styles';
import theme from '@/utils/styles-variables';

function SideImageList({ id, header, image, imagePosition, list, footer, ...rest }) {
  const imageData = getImgData(image);
  const imageFooterData = getImgData(footer?.image);

  return (
    <Section id={`side-image-list-${id}`} color={theme.colors.neutral.default} {...rest}>
      <Container>
        <Intro {...header} innerSpacer />
        <Wrapper $imageRight={imagePosition === 'right'}>
          <ImageContent>
            {imageData && (
              <Image
                src={imageData.url}
                alt={imageData.alternativeText || ''}
                width={644}
                height={644}
              />
            )}
          </ImageContent>
          <WrapperInfo>
            <InfoContent>
              {list?.map((item) => (
                <Content key={item.key}>
                  <Title as="h6" size="xs">
                    {item.title}
                  </Title>
                  <Paragraph as="p">{item.description}</Paragraph>
                </Content>
              ))}
            </InfoContent>
            {footer && (
              <Footer>
                <FooterTitle>{footer.title}</FooterTitle>
                <figure>
                  {imageFooterData && (
                    <Image
                      src={imageFooterData.url}
                      alt={imageFooterData.alternativeText || ''}
                      width={179}
                      height={40}
                    />
                  )}
                </figure>
                <Paragraph as="p">{footer.description}</Paragraph>
              </Footer>
            )}
          </WrapperInfo>
        </Wrapper>
      </Container>
    </Section>
  );
}

export default SideImageList;
