import styled, { keyframes } from 'styled-components';

import { Container } from '@swordhealth/ui-corporate';
import { GAP, GAP_SM, GAP_XS } from '@/components/core/Section/Wrapper';
import { Title } from '@/components/core';

export const CustomContainer = styled(Container)`
  --container-max-width: 1600px;
`;

export const Wrapper = styled(Container)`
  --container-max-width: 1600px;
  --wrapper-spacing: ${GAP}px;

  padding-bottom: var(--wrapper-spacing);
  padding-top: var(--wrapper-spacing);
  border-radius: 40px;
  position: relative;
  overflow: clip;

  @media (max-width: ${(props) => props.theme.breakpoints.maxMd}) {
    --wrapper-spacing: ${GAP_SM}px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.maxSm}) {
    --wrapper-spacing: ${GAP_XS}px;
    border-radius: 16px;
  }
`;
const layerAnimation = keyframes`
  0% {
		transform: translate(-46%,-28%);
	}
	20% {
		transform: translate(-6%, -28%);
	}
  40% {
    transform: translate(-38%,-18%);
  }
  60% {
    transform: translate(-1%,-5%);
  }
	80% {
		transform: translate(-6%, 10%);
	}
  100% {
		transform: translate(-46%,-28%);
	}
`;

export const LayerOne = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(
    49deg,
    rgba(89, 147, 234, 0) 48%,
    rgba(222, 104, 105, 0.2) 75%,
    rgba(222, 104, 105, 0.2) 100%
  );
  z-index: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
`;
export const LayerTwo = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(
    211deg,
    rgba(89, 147, 234, 0) 35%,
    rgba(222, 104, 105, 0.2) 85%,
    rgba(222, 104, 105, 0.2) 100%
  );
  z-index: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
`;
export const LayerThree = styled.div`
  pointer-events: none;
  position: absolute;
  background: radial-gradient(
    circle,
    rgba(205, 223, 248, 1) 0%,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 170%;
  height: 170%;
  z-index: 1;
  top: 0;
  left: 0;
  transform: translate(-46%, -28%);
  border-radius: 100%;
  animation: ${layerAnimation} 15s infinite linear;
`;
export const Content = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.maxMd}) {
    gap: 64px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.maxSm}) {
    gap: 40px;
  }
`;
export const Counter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;

  .title {
    text-align: center;
    max-width: 800px;
  }
  .description {
    max-width: 800px;
    text-align: center;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.maxSm}) {
    gap: 16px;
  }
`;
export const CounterNumber = styled(Title)`
  font-variant-numeric: tabular-nums;

  @media (min-width: ${(props) => props.theme.breakpoints.minMd}) {
    font-size: 76px;
    line-height: 80px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.minLg}) {
    font-size: 120px;
    line-height: 120px;
  }
`;
export const Cards = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, minmax(181px, 1fr));
  grid-gap: 40px;
  width: 100%;
  max-width: 1024px;

  @media (max-width: ${(props) => props.theme.breakpoints.maxSm}) {
    grid-template-columns: 1fr;
  }
`;
export const CardList = styled.li`
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: center;

  .title {
    color: ${(props) => props.theme.colors.grey[600]};
  }
  .description {
    text-align: center;

    sup {
      font-family: ${(props) => props.theme.typography.family.body};
      color: ${(props) => props.theme.colors.primary.default};
      font-size: 80%;
      font-weight: 700;
      vertical-align: top;
      line-height: 1;
      cursor: pointer;
    }
  }
`;
