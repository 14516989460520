import Image from 'next/image';

import { getImgData } from '@/utils/images';
import { Section } from '@/components/core';
import Intro from '../Intro';
import { ImageWrapper } from './styles';
import theme from '@/utils/styles-variables';

function ImageWithIntro({ content, colored = true, ...rest }) {
  if (!content) return null;

  const { header, image } = content;
  const imageData = getImgData(image);

  return (
    <Section color={colored ? theme.colors.neutral.default : undefined} {...rest}>
      <Intro {...header} textSize="md" size="sm" innerSpacer />
      {imageData && (
        <ImageWrapper>
          <Image
            src={imageData.url}
            alt={imageData.alternativeText || ''}
            width={imageData.width}
            height={imageData.height}
          />
        </ImageWrapper>
      )}
    </Section>
  );
}

export default ImageWithIntro;
