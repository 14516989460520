'use client';

import { memo, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import Image from 'next/image';

import theme from '@/utils/styles-variables';
import { getImgData } from '@/utils/images';
import { trackDownload } from '@/utils/track';
import { getFile } from '@/services/resources';
import { GatedResourceForm } from '../GatedResourceForm';
import {
  CustomSection,
  CustomHeader,
  CustomContainer,
  SuccessImage,
  StyledUpperText,
} from './styles';
import { Paragraph } from '@/components/core';
import { Button } from '@/components/core/Button';
import { Heading } from '@swordhealth/ui-corporate';

function getContent({ content, isSuccess, postType }) {
  const gatedContent = content?.gatedContent?.[0];

  if (!gatedContent) return null;

  const details = {
    slug: content.slug,
    title: content.title,
    subtitle: gatedContent.gatedScreen.summary,
    upperLabel: `${postType.label}${
      content?.date
        ? ` • ${new Date(content.date).toLocaleDateString('en-US', { dateStyle: 'long' })}`
        : ''
    }`,
  };

  if (isSuccess) {
    const gatedSuccess = gatedContent?.gatedSuccess;

    details.title = gatedSuccess?.title;
    details.subtitle = gatedSuccess?.description;
    details.upperLabel = null;
    details.image = getImgData(gatedContent.gatedScreen.image);

    if (gatedContent?.fileUrl) {
      details.file = {
        href: gatedContent?.fileUrl,
        label: 'Download now',
      };
    }
  }

  return details;
}

const Header = ({ content, isSuccess, postType }) => {
  const pathname = usePathname();
  const formatContent = getContent({ content, isSuccess, postType });

  useEffect(() => {
    async function fetchData(formatContent) {
      try {
        const fileUrl = formatContent?.file?.href;
        const { blob, name } = await getFile(fileUrl, formatContent.slug);

        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = name;
        link.click();
        URL.revokeObjectURL(href);

        trackDownload({ fileUrl, pageUrl: pathname, automatic: true });
      } catch (error) {
        console.error('downloadFile', error);
      }
    }

    async function lock() {
      try {
        await fetch(`${process.env.NEXT_PUBLIC_DEPLOYMENT_URL}/api/resources/gated/lock`);
      } catch (error) {
        console.error('lock', error);
      }
    }

    if (isSuccess) {
      window.scrollTo(0, 0);

      if (formatContent?.file?.href) {
        fetchData(formatContent);
      }

      lock();
    }
  }, [isSuccess, formatContent, pathname]);

  if (!formatContent) return null;

  return (
    <CustomSection
      $isSuccess={isSuccess}
      color={isSuccess ? theme.colors.neutral.default : null}
      lastColored={isSuccess}
    >
      <CustomContainer $isSuccess={isSuccess}>
        <CustomHeader $isSuccess={isSuccess}>
          {isSuccess && <Image src="/assets/resources/success.svg" alt="" width={64} height={64} />}
          {formatContent.upperLabel && (
            <StyledUpperText>{formatContent.upperLabel}</StyledUpperText>
          )}
          <Heading as="h1">{formatContent.title}</Heading>
          <Paragraph size="lg">{formatContent.subtitle}</Paragraph>
          {formatContent.file && (
            <Button
              href={formatContent.file.href}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                trackDownload({
                  fileUrl: formatContent.file.href,
                  pageUrl: pathname,
                  automatic: false,
                })
              }
            >
              {formatContent.file.label}
            </Button>
          )}
        </CustomHeader>
        {formatContent.image && (
          <SuccessImage
            alt={formatContent.image.alternativeText || ''}
            width={formatContent.image.width}
            height={formatContent.image.height}
            src={formatContent.image.url}
          />
        )}
        {!isSuccess && <GatedResourceForm postType={postType} postSlug={content.slug} />}
      </CustomContainer>
    </CustomSection>
  );
};

export default memo(Header);
