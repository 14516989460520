import { Section, Title } from '@/components/core';
import { Col, Container, Row } from '@/components/core/flexbox';
import Content from '../Content';
import Header from '../Header';
import { VisitResource } from './styles.js';
import { PostsList } from '../../Blog';
import { Button } from '@/components/core/Button';

const GatedPage = ({ isSuccess, content, postType }) => {
  const gatedContent = content?.gatedContent?.[0];

  if (!gatedContent) return null;

  return (
    <>
      <Header content={content} isSuccess={isSuccess} postType={postType} />
      <Section md="120" sm="80" xs="48">
        {!isSuccess ? (
          <Container>
            <Row>
              <Col xs={12} md={12} lg={6}>
                <Content {...gatedContent?.gatedScreen} />
              </Col>
            </Row>
          </Container>
        ) : (
          <>
            <Container>
              <Title as="h3" size="md">
                Other relevant resources
              </Title>
            </Container>
            <PostsList posts={content.relatedResources} hideWhenEmpty hasReadingTime={true} />
            <Container>
              <VisitResource>
                <Button variant="tertiary" href="/resources">
                  Visit our resource center
                </Button>
              </VisitResource>
            </Container>
          </>
        )}
      </Section>
    </>
  );
};

export default GatedPage;
