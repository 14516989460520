import styled from 'styled-components';
import Image from 'next/image';

import { Heading } from '@swordhealth/ui-corporate';
import getImgSrcWorkaround from '@/utils/images';
import { Paragraph } from '@/components/core';

const Wrapper = styled.div`
  align-content: start;
  display: inline-grid;
  gap: 24px;
  justify-items: center;
  width: calc(100% / var(--columns) - var(--gap));
  text-align: center;
`;

const PersonImage = styled.div`
  aspect-ratio: 1 / 1;
  width: 160px;
  border-radius: 99999px;
  overflow: hidden;
  position: relative;

  img {
    object-fit: cover;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 140px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 100px;
  }
`;

const PersonRole = styled(Paragraph)`
  color: ${(props) => props.theme.colors.grey.default};
  margin-top: 8px;
`;

const Person = ({ name, role, photo, id }) => {
  return (
    <Wrapper data-testid="item" data-id={id}>
      <PersonImage>
        <Image
          src={getImgSrcWorkaround(photo.data?.attributes.url)}
          alt={photo.data?.attributes.alternativeText || ''}
          fill
          sizes="320px"
        />
      </PersonImage>
      <div>
        <Heading size="xxs">{name}</Heading>
        <PersonRole isMarkdown={false}>{role}</PersonRole>
      </div>
    </Wrapper>
  );
};

export default Person;
