'use client';

import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';
import { Number } from './styles';

function CounterComponent({ value }) {
  const [isVisible, setIsVisible] = useState(false);

  const [reference, inView] = useInView({
    threshold: 0,
    initialInView: false,
  });

  useEffect(() => {
    if (inView && !isVisible) {
      setIsVisible(true);
    }
  }, [inView, isVisible]);

  return (
    <Number ref={reference} data-testid="counter-component">
      {isVisible ? <CountUp start={0} end={value} duration={3} /> : '--'}
    </Number>
  );
}

export default CounterComponent;
